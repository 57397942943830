// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-content{
    row-gap: 40px;
}
  /*About Section*/
  .section-a{
    font-size: 44px;
    color: black;
    padding: 20px 0;
    position: relative;
    line-height: 1.2;
  }
  .section-a p{
    margin-top: 26px;
  }
  .section-a::before{
    content: "";
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    height: 5px;
    width: 60px;
    background: rgb(87, 100, 222);
    background: linear-gradient(45deg, rgba(87, 100, 222, 1) 0%, rgba(85, 179, 213, 1) 100%);
  }

  /*Screen Responsiveness*/
  @media screen and (max-width: 768px){
    .section-a{
        text-align: center;
      }
      .section-a::before{
        left: 45%;
        transform: translateX(0);
      }
}

@media screen and (min-width: 992px){
    .about-content{
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        column-gap: 60px;
    }
    .section-a{
        text-align: left;
      }
      .section-a::before{
        left: 0;
        transform: translateX(0);
      }
}
  
  /* hover effect */
  .translate-effect:hover{
    transform: translateY(-20px);
  }`, "",{"version":3,"sources":["webpack://./src/components/About/About.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;EACE,gBAAgB;EAChB;IACE,eAAe;IACf,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,WAAW;IACX,WAAW;IACX,6BAA6B;IAC7B,wFAAwF;EAC1F;;EAEA,wBAAwB;EACxB;IACE;QACI,kBAAkB;MACpB;MACA;QACE,SAAS;QACT,wBAAwB;MAC1B;AACN;;AAEA;IACI;QACI,qCAAqC;QACrC,mBAAmB;QACnB,gBAAgB;IACpB;IACA;QACI,gBAAgB;MAClB;MACA;QACE,OAAO;QACP,wBAAwB;MAC1B;AACN;;EAEE,iBAAiB;EACjB;IACE,4BAA4B;EAC9B","sourcesContent":[".about-content{\n    row-gap: 40px;\n}\n  /*About Section*/\n  .section-a{\n    font-size: 44px;\n    color: black;\n    padding: 20px 0;\n    position: relative;\n    line-height: 1.2;\n  }\n  .section-a p{\n    margin-top: 26px;\n  }\n  .section-a::before{\n    content: \"\";\n    position: absolute;\n    top: 10px;\n    left: 50%;\n    transform: translateX(-50%);\n    height: 5px;\n    width: 60px;\n    background: rgb(87, 100, 222);\n    background: linear-gradient(45deg, rgba(87, 100, 222, 1) 0%, rgba(85, 179, 213, 1) 100%);\n  }\n\n  /*Screen Responsiveness*/\n  @media screen and (max-width: 768px){\n    .section-a{\n        text-align: center;\n      }\n      .section-a::before{\n        left: 45%;\n        transform: translateX(0);\n      }\n}\n\n@media screen and (min-width: 992px){\n    .about-content{\n        grid-template-columns: repeat(2, 1fr);\n        align-items: center;\n        column-gap: 60px;\n    }\n    .section-a{\n        text-align: left;\n      }\n      .section-a::before{\n        left: 0;\n        transform: translateX(0);\n      }\n}\n  \n  /* hover effect */\n  .translate-effect:hover{\n    transform: translateY(-20px);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
