// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qualities .section-t{
    margin-bottom: 30px;
}
.qualities .item-icon{
    margin-right: 28px;
    font-size: 46px;
}
.qualities .item-title{
    margin-top: 0;
    color: aliceblue;
}
.qualities .item-list{
    row-gap: 30px;
}
.qualities-content{
    row-gap: 60px;
}


@media screen and (min-width: 992px){
    .qualities-content{
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        column-gap: 60px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Qualities/Qualities.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,aAAa;IACb,gBAAgB;AACpB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;AACjB;;;AAGA;IACI;QACI,qCAAqC;QACrC,mBAAmB;QACnB,gBAAgB;IACpB;AACJ","sourcesContent":[".qualities .section-t{\n    margin-bottom: 30px;\n}\n.qualities .item-icon{\n    margin-right: 28px;\n    font-size: 46px;\n}\n.qualities .item-title{\n    margin-top: 0;\n    color: aliceblue;\n}\n.qualities .item-list{\n    row-gap: 30px;\n}\n.qualities-content{\n    row-gap: 60px;\n}\n\n\n@media screen and (min-width: 992px){\n    .qualities-content{\n        grid-template-columns: repeat(2, 1fr);\n        align-items: center;\n        column-gap: 60px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
