import header_main_img from "../../assets/images/hero1.jpg";
import professionals_main_img from "../../assets/images/hero1.jpg";
// import video_cover_img from "../../assets/images/img-3.jpg";

import portfolio_img_1 from "../../assets/images/websiteimg.png";
import portfolio_img_2 from "../../assets/images/mobile1.jpg";
import portfolio_img_3 from "../../assets/images/ai.jpg";

const images = {header_main_img, professionals_main_img, portfolio_img_1, portfolio_img_2, portfolio_img_3};

export default images;